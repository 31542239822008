<template>
  <div class='common_box'>
    <div class="common_title">
      <h3>添加用户</h3>
      <el-button class="btn_info" style="margin-right:18px" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="userForm">
      <el-form :model="userForm" :rules="rules" label-width="120px" ref="userForm" :hide-required-asterisk="true">
        <el-form-item prop="name" label="姓名： ">
          <el-input v-model="userForm.name" placeholder="请输入姓名" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机： ">
          <el-input v-model="userForm.phone" placeholder="请输入您的手机号" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="mail" label="邮箱： ">
          <el-input v-model="userForm.mail" placeholder="请输入您的邮箱" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="username" label="用户名： ">
          <el-input v-model="userForm.username" placeholder="请输入用户名" size="small">
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="初始密码： ">
          <el-input v-model="userForm.password" placeholder="请输入初始密码" size="small">
          </el-input>
        </el-form-item>

        <el-form-item prop="rids" label="用户类型： ">
          <el-select v-model="userForm.rids" placeholder="请选择单位类型" size="small">
            <el-option v-for="item in userType" :key="item.id" :label="item.namezh" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属区域" required>
          <el-col :span="8">
            <el-form-item prop="grade">
              <el-select v-model="userForm.grade" placeholder="请选择用户所属层级" size="small" @change="gradeChange">
                <el-option label="市级" value="1"></el-option>
                <el-option label="区级" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item prop="adcodes">
              <el-cascader clearable v-model="userForm.adcodes" :options="cityList" :props="{ expandTrigger: 'hover',label: 'name', value: 'adcode', children: 'children' }" size="small" style="width:100%" placeholder="请选择用户所属区域">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="siteId" label="所属单位：">
          <el-select v-model="userForm.siteId" placeholder="请选择所属单位" size="small">
            <el-option v-for="item in nameList" :key="item.id" :label="item.value" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="userForm.remark" type="textarea" placeholder="请输入备注（选填）" size="small">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loginLoading" @click="submitForm('userForm')">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addUser, getUserType, getRegion, getSiteNameList } from '@/api'
export default {
  data () {
    return {
      userType: [],
      userForm: {
        name: '',
        phone: '',
        mail: '',
        username: '',
        password: '',
        rids: '',
        grade: '',
        adcodes: '',
        siteId: ''
      },
      rules: {
        name: [
          { required: true, validator: this.$validator.username, trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: this.$validator.phone, trigger: 'blur' }
        ],
        mail: [
          { required: true, validator: this.$validator.email, trigger: 'blur' }
        ],
        username: [
          { required: true, validator: this.$validator.username, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: this.$validator.password, trigger: 'blur' }
        ],
        rids: [
          { required: true, message: '请选择用户类型', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入单位详细地址', trigger: 'blur' }
        ],
        grade: [
          { required: true, message: '请选择用户所属层级', trigger: 'change' }
        ],
        adcodes: [
          { required: true, message: '请选择用户所属区域', trigger: 'change' }
        ],
        siteId: [
          { required: true, message: '请选择用户所属层级', trigger: 'change' }
        ]
      },
      loginLoading: false,
      cityList: [],
      oldList: [],
      nameList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      // 用户类型
      getUserType().then(res => {
        this.userType = res.data.data
      })
      // 省市区级联
      getRegion(330000).then(res => {
        this.cityList = this.formatData(res.data.data)
        this.oldList = res.data.data
      })
      // 获取单位列表
      getSiteNameList().then(res => {
        let list = []
        for (var i in res.data.data) {
          list.push({
            id: i,
            value: res.data.data[i]
          })
        }
        this.nameList = list
      })
    },
    // 选择用户所属层级
    gradeChange (val) {
      // const oldList = JSON.parse(JSON.stringify(this.oldList))
      let citys = JSON.parse(JSON.stringify(this.oldList))
      if (val === '1') {
        citys[0].children.forEach(el => {
          el.children = []
        })
        // console.log(citys)
        this.cityList = this.formatData(citys)
      } else {
        // console.log(this.oldList)
        this.cityList = this.oldList
      }
    },
    // 处理级联数据
    formatData (data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          data[i].children = undefined
        } else {
          this.formatData(data[i].children)
        }
      }
      return data
    },
    submitForm () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          addUser(this.$tools.concatParams(this.userForm)).then(() => {
            this.$message.success('添加用户成功')
            this.$router.go(-1)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.userForm {
  ::v-deep .el-form {
    width: 55%;
    margin: 0 auto;
    .el-form-item__label {
      color: #30c6fe;
    }
    .el-form-item__content {
      text-align: right;
      .el-input {
        .el-input__inner {
          background: none;
          border: 1px solid #30c6fe;
          color: #fff;
        }
      }
      .is-disabled {
        .el-input__inner {
          border: none;
        }
      }
      .el-select {
        width: 100%;
      }
      .el-textarea {
        .el-textarea__inner {
          background: none;
          border: 1px solid #30c6fe;
          color: #fff;
        }
      }
    }
  }
}
</style>
